import React from 'react'
//import { Link } from 'gatsby'
import AvatarImg from '../components/images/image-avatar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'

const Avatar = props => (
    <div className="col-4 col-tls-2 col-mvp-1 ansprechpartner-item">
        <AvatarImg src={props.src} title={props.title} />
        <div className="avatar-details">
            <div className="avatar-name">
                {props.name}
            </div>
            <div className="avatar-job">
                {props.job}
            </div>
            <div className="avatar-contact">
                <div className="avatar-contact--mail">
                    {/*
                    condition within rendering
                 */}

                    {props.mail &&
                    <a href={"mailto:" + props.mail}>
                        <FontAwesomeIcon className="avatar-icon" icon={faEnvelope} /> {props.mail}
                    </a>}
                </div>
                <div className="avatar-contact--phone">
                    <a href={"tel:" + props.telLink}>
                        <FontAwesomeIcon className="avatar-icon" icon={faPhone} /> {props.telString}
                    </a>
                </div>
            </div>
        </div>
    </div>
)

export default Avatar;