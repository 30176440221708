import React from "react"

import ServiceText from "./service-text"
import TeaserImg from "./images/image-teaser"

const Teaser = props => (
    <div className="teaser-wrapper">
        <div className="teaser-image">
            <TeaserImg src={props.src} title={props.title} />
        </div>
        <div className={"teaser-layer teaser-layer-" + props.textPosition}> </div>
        <div className={"teaser-layer-grey teaser-layer-" + props.textPosition}> </div>
        <ServiceText className={"teaser-text teaser-text-" + props.textPosition} headline={props.headline}>
            {props.children}
        </ServiceText>
    </div>
)

export default Teaser