import React from 'react'

const ServiceText = props => (
    <div className={props.className}>
        <div className="col-inner">
            <h2>{props.headline}</h2>
            <div className="paragraph">{props.children}</div>
        </div>
    </div>
)

export default ServiceText