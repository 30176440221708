import React from "react";

import ImageBanner from "../components/images/image-banner"

const Banner = props => (
    <div className='banner-wrapper'>
        <ImageBanner className='banner-image' title={props.title} />
        <div className='banner-layer'> </div>
        <div className='banner-layer-gray'> </div>
        <div className='banner-claim'>
            <div className='container'>
                <span>
                    {props.children}
                </span>
            </div>
        </div>
    </div>
)

export default Banner