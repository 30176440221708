import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';



const Box = () => {
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function imageArrayReferenzen(quality) {
        return [
            '../images/referenzen/REF' + quality + '1.jpg',
            '../images/referenzen/REF' + quality + '2.jpg',
            '../images/referenzen/REF' + quality + '3.jpg',
            '../images/referenzen/REF' + quality + '4.jpg',
            '../images/referenzen/REF' + quality + '5.jpg',
            '../images/referenzen/REF' + quality + '6.jpg',
            '../images/referenzen/REF' + quality + '7.jpg',
            '../images/referenzen/REF' + quality + '8.jpg',
            '../images/referenzen/REF' + quality + '9.jpg',
            '../images/referenzen/REF' + quality + '10.jpg',
            '../images/referenzen/REF' + quality + '11.jpg',
            '../images/referenzen/REF' + quality + '12.jpg',
        ];

    }

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }

    function renderReferenzen() {
        const referenzen = imageArrayReferenzen('LQ');
        return referenzen.map(
            (referenz, index) => (
                <div className="col-3 col-tvp-2 col-mvp-1 referenzen-item" key={index +1}>
                    <div className="referenzen-image">
                        <img src={referenz} alt="Referenz" onClick={() => openLightboxOnSlide(index +1)}
                             onKeyPress={() => openLightboxOnSlide(index +1)} role="presentation" />
                    </div>
                </div>
            )
        );
    }


    return(<div>
        {renderReferenzen()}
        <FsLightbox
            toggler={lightboxController.toggler}
            sources={imageArrayReferenzen('HQ')}
            slide={lightboxController.slide}
            thumbs={imageArrayReferenzen('LQ')}
        />
    </div>)
}

export default Box;